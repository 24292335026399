import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { createWorker } from "tesseract.js";
import axios from "../../../api/axios-token";

import {
  Button,
  Modal,
  Input,
  Progress,
  Icon,
  Message,
} from "semantic-ui-react";
import Resizer from "react-image-file-resizer";
import amplitude from "amplitude-js";

const CameraScanner = (props) => {
  const [image, setImage] = useState(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const fileRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const cropRef = useRef(null);
  const [scanner, setScanner] = useState(0);
  const [scanning, setScanning] = useState(false);
  const [scanningCaption, setScanningCaption] = useState("Scanning file");
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const pixelRatio = window.devicePixelRatio || 1;

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function resetScanner() {
    setImage(null);
    setScanner(0);
    setScanning(false);
    setScanningCaption("Scanning file");
  }

  const blobToBase64 = function (blob) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = function () {
        let dataUrl = reader.result;
        resolve(dataUrl);
      };
      reader.readAsDataURL(blob);
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2000,
        2000,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const apiText = async () => {
    setError(null);
    setScanning(true);
    if (!upImg) {
      return;
    }
    var img = new Image();
    img.src = upImg;
    const croppedImg = await getResizedCanvas();
    const originalImg = croppedImg;
    setScanningCaption("Reading text");

    croppedImg.toBlob(async function (blob) {
      // const image = await blobToBase64(blob);
      const image = await resizeFile(blob);
      var bodyFormData = new FormData();
      var bodyFormData = new FormData();

      bodyFormData.set("file", image);

      axios
        .post("/recipes/scan", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async function (response) {
          if (response.data && !response.data.error) {
            props.updateText(response.data.text);
            setModalOpen(false);
            resetScanner();
            amplitude.getInstance().logEvent("Scanned Text");
          } else {
            setError(response.data.ErrorDetails);
            amplitude.getInstance().logEvent("Scanning API Failed");

            // const worker = createWorker({
            //   logger: (m) => {
            //     if (m.status == "recognizing text") {
            //       setScanningCaption("Reading text");
            //       setScanner(Math.round(m.progress * 100));
            //     }
            //   },
            // });

            // await worker.load();
            // await worker.loadLanguage("eng");
            // await worker.initialize("eng");

            // await worker.setParameters({
            //   tessedit_char_whitelist:
            //     " 0123456789¼½¾⅓⅔abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-()/.-",
            // });
            // const {
            //   data: { text },
            // } = await worker.recognize(originalImg);

            // const textArray = text.split(/\r\n|\n|\r/);

            // // var ingredients = [" 4-5", "1 thsp olive oil", "1 small aubergine (about 250g) cut", "into 3cm dice", "2 medium onions halved and", "finely sliced", "1 yellow pepper deseeded and cut", "into 3cm dice", "1 red pepper deseeded and cut into", "3cm dice", "1 medium sweet potato (300g)", "peeled and curt into 2cm dice", "1 tsp ground coriander", "2-1 tsp hot chilli powder", "2 tsp smoked paprika (hot)", "400g can of chopped tomatoes", "400g can of cannellini beans rinsed", "and drained", "600ml cold water", "1 large courgette halved lengthways", "and cut into 15cm slices", "2 tsp cornflour", "2 tsp cold water", " freshly squeezed juice of 2 lime", "4 tbsp half-fat creme fraiche", "flaked sea salt", " fresh coriander leaves to garnish", "l(optional)", "ime wedges for squeezing", ""];

            // const newArray = textArray.reduce(function (acc, curr, index, arr) {
            //   if (/^\s*\d+.*/.test(curr.trim())) {
            //     return [...acc, curr.trim()];
            //   } else {
            //     var prev = acc[acc.length - 1];

            //     if (/^\s*\d+.*/.test(prev)) {
            //       var combine = acc[acc.length - 1] + " " + curr.trim();
            //       console.log(combine);
            //       acc[acc.length - 1] = combine;
            //       return acc;
            //     } else {
            //       return acc;
            //     }
            //   }
            // }, []);

            // props.updateText(newArray.join("\n"));
            // await worker.terminate();

            // setModalOpen(false);
            resetScanner();
            return;
          }
        })
        .catch(async function (error) {
          setError(
            "The connection to the scanning server timed out. Please try again "
          );
          amplitude.getInstance().logEvent("Scanning API Failed");

          // const worker = createWorker({
          //   logger: (m) => {
          //     if (m.status == "recognizing text") {
          //       setScanningCaption("Reading text");
          //       setScanner(Math.round(m.progress * 100));
          //     }
          //   },
          // });

          // await worker.load();
          // await worker.loadLanguage("eng");
          // await worker.initialize("eng");

          // await worker.setParameters({
          //   tessedit_char_whitelist:
          //     " 0123456789¼½¾⅓⅔abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-()/.-",
          // });
          // const {
          //   data: { text },
          // } = await worker.recognize(originalImg);

          // const textArray = text.split(/\r\n|\n|\r/);

          // // var ingredients = [" 4-5", "1 thsp olive oil", "1 small aubergine (about 250g) cut", "into 3cm dice", "2 medium onions halved and", "finely sliced", "1 yellow pepper deseeded and cut", "into 3cm dice", "1 red pepper deseeded and cut into", "3cm dice", "1 medium sweet potato (300g)", "peeled and curt into 2cm dice", "1 tsp ground coriander", "2-1 tsp hot chilli powder", "2 tsp smoked paprika (hot)", "400g can of chopped tomatoes", "400g can of cannellini beans rinsed", "and drained", "600ml cold water", "1 large courgette halved lengthways", "and cut into 15cm slices", "2 tsp cornflour", "2 tsp cold water", " freshly squeezed juice of 2 lime", "4 tbsp half-fat creme fraiche", "flaked sea salt", " fresh coriander leaves to garnish", "l(optional)", "ime wedges for squeezing", ""];

          // const newArray = textArray.reduce(function (acc, curr, index, arr) {
          //   if (/^\s*\d+.*/.test(curr.trim())) {
          //     return [...acc, curr.trim()];
          //   } else {
          //     var prev = acc[acc.length - 1];

          //     if (/^\s*\d+.*/.test(prev)) {
          //       var combine = acc[acc.length - 1] + " " + curr.trim();
          //       console.log(combine);
          //       acc[acc.length - 1] = combine;
          //       return acc;
          //     } else {
          //       return acc;
          //     }
          //   }
          // }, []);

          // props.updateText(newArray.join("\n"));

          // setModalOpen(false);
          //
          // await worker.terminate();
          resetScanner();
          return;
        });
    });
  };
  const getResizedCanvas = () => {
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = Math.ceil(crop.width * scaleX);
    tmpCanvas.height = Math.ceil(crop.height * scaleY);

    const ctx = tmpCanvas.getContext("2d");
    const image = imgRef.current;
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return tmpCanvas;
  };

  function getCroppedImg(image, crop, fileName) {
    // console.log(cropRef.current.componentRef.scrollWidth);

    const canvas = document.createElement("canvas");
    const scaleX =
      image.naturalWidth / cropRef.current.componentRef.scrollWidth;
    const scaleY =
      image.naturalHeight / cropRef.current.componentRef.scrollHeight;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <Modal
      open={modalOpen}
      size={"fullscreen"}
      trigger={
        <Button
          data-tour="scan"
          color={props.color}
          onClick={() => setModalOpen(true)}
          type="button"
        >
          <Icon
            size={props.size}
            style={{ paddingRight: "0.5em" }}
            name="camera retro"
          />
          {props.text}
        </Button>
      }
    >
      <Modal.Header>Text Scanner</Modal.Header>
      <Modal.Content>
        <>
          {scanning ? (
            <Progress
              color="green"
              indicating
              size="large"
              percent={parseInt(scanner)}
            >
              {scanningCaption}
            </Progress>
          ) : (
            <>
              {error ? (
                <Message negative>
                  <Message.Header>Scanning failed</Message.Header>
                  {error}
                </Message>
              ) : null}
              {!upImg && (
                <Message
                  info
                  header="Scanning Tips"
                  content="Try and get your image as in focus as possible. Use the crop tool to select only the relevant text of your recipe. If you're scanning from a book, ensure your image has no glare and the book page is as flat as possible. Always double check your scans for minor corrections."
                />
              )}
              <Button
                content="Take or upload a picture"
                labelPosition="left"
                icon="camera"
                size="large"
                color="green"
                fluid
                onClick={() => fileRef.current.click()}
              />

              <input
                ref={fileRef}
                hidden
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
              <ReactCrop
                ref={cropRef}
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
            </>
          )}
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setModalOpen(false);
            resetScanner();
          }}
          type="button"
        >
          Cancel
        </Button>
        <Button
          loading={scanning}
          type="button"
          onClick={() => apiText()}
          type="button"
        >
          Select Image Text
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CameraScanner;
